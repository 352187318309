@import '../App/InvestoristColours';

.icon-text-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    font-weight: bold;
  }
}

.button-as-nav-link.btn-default,
.button-as-nav-link.button-default,
.button-as-nav-link.btn-default:active,
.button-as-nav-link.button-default:active,
.button-as-nav-link.btn-default:focus,
.button-as-nav-link.button-default:focus,
.button-as-nav-link.btn-default:active:focus,
.button-as-nav-link.button-default:active:focus {
  background-color: $colour-white-pure;
  color: $colour-grey-darkest;
  box-shadow: none;
  -webkit-box-shadow: none;
  
  span {
    font-weight: bold;
  }
}

.button-as-nav-link.btn-default:hover,
.button-as-nav-link.button-default:hover {
  background-color: $colour-white-pure;
  color: var(--secondary-brand-colour);

  svg path {
    fill: var(--secondary-brand-colour);
  }
}

.home-logo {
  margin-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  
  img {
    max-width: 130px;
    max-height: 40px;
    margin: auto;
  }

  .minLogoHeight {
    min-height: 40px;
  }
}

.extended-home-logo {
  height: 100%;

  img {
    max-height: 90px;
  }
}

.main-navigation-bar {
  background-color: $colour-white-pure;
  border-top: 1px solid $colour-grey-light;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;

  ul.nav {
    display: flex;
    justify-content: center;

    li {
      a {
        font-size: 14px;
        font-weight: bold;
        color: $colour-grey-darkest;
      }

      a:focus, a:hover {
        background-color: $colour-white-pure;
        color: var(--secondary-brand-colour);

        svg path {
          fill: var(--secondary-brand-colour);
        }

        span {
          color: var(--secondary-brand-colour);
        }
      }
    }
  }

  .portal-management {
    .caret {
      display: none;
    } 

    span {
      word-wrap: nowrap;
    }

    .dropdown-menu {
      width: 100%;
      border: none;

      li {
        a {
          padding: 10px 10px 10px 20px;
          font-weight: 400;
          line-height: 1.42857143;
          white-space: nowrap;

          .icon-text-content-wrapper {
            display: block;
            padding-left: 15px;
          }
        }

        a:hover, a:focus {
          background-color: $colour-grey-light;
        }
      }
    }
  } 
}

@media (max-width: 768px) {
  .main-navigation-bar {
    min-height: 60px;
  }
  .main-navigation-bar ul.nav li a .icon-text-content-wrapper{
    display: block;
    text-align: center;
  }
}

.administration-bar {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  a {
    margin: auto;
  }

  .icon-button {
    margin: 0 15px;
    background-color: $colour-white-pure;

    .icon {
      margin-right: 0px;

      path {
        stroke: $colour-grey-darkest;
      }
    }
  }

  .icon-button:hover,
  .icon-button:active {
    .icon {
      path {
        stroke: var(--secondary-brand-colour);
      }
    }
  }

  .loginButton,
  .loginButton:focus,
  .loginButton:hover,
  .loginButton:active,
  .loginButton:active:focus {
    color: var(--secondary-brand-colour);
    background-color: $colour-white-pure;
    border: 1px solid var(--secondary-brand-colour);
    box-shadow: none;
  }

  .navbar-nav {
    margin-right: -15px;
  }

  .navbar-right {
    display: flex;
    align-items: stretch;

    a {
      white-space: nowrap;
      font-size: 14px;
      font-weight: bold;
      color: $colour-grey-darkest;
    }

    a:focus,
    a:hover,
    .open>a,
    .open>a:focus,
    .open>a:hover {
      background-color: $colour-white-pure;
      color: var(--secondary-brand-colour);
    }

    .dropdown {
      position: static;
      left: 2px;
      right: 2px;
      display: flex;
      align-items: center;

      img {
        width: 30px;
      }
    }

    .dropdown-menu {
      position: absolute;
      padding: 10px 0;
      top: calc(100% + 10px);
      right: 10px;
      left: 10px;

      background-color: $colour-white-pure;
      border-radius: 5px;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);

      .dropdown-header {
        padding: 5px 25px;
        font-size: 14px;
        font-weight: bold;
      }

      img.languageFlag {
        max-width: 20px;
        max-height: 20px;
      }

      button {
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        color: $colour-grey-darkest;
        padding: 6px;
        background-color: $colour-white-pure;
        display: flex;
        align-items: center;
      }

      button:active,
      button.active {
        background-color: $colour-grey-light;
        border: 1px solid $colour-grey-mid;
        box-shadow: none;
      }

      button:hover {
        background-color: $colour-grey-light;
      }

      .language-buttons {
        padding: 5px 25px 0 25px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        &>div {
          padding-right: 10px;
          padding-left: 10px;
        }

        .language-button {
          padding-left: 10px;
          margin-bottom: 5px;
          text-align: left;
          width: 120px;

          &:active, &:hover, &:focus {
            color: $colour-grey-darkest;
          }
        }
      }

      .measurement-buttons {
        padding: 5px 25px 0 25px;
        display: flex;
        justify-content: space-between;

        &>div {
          padding-right: 10px;
          padding-left: 10px;
        }

        .measurement-button {
          padding-left: 10px;
          margin-bottom: 5px;
          text-align: left;
          width: 120px;

          &:active, &:hover, &:focus {
            color: $colour-grey-darkest;
          }
        }
      }

      .icon-text-content-wrapper {
        justify-content: left;
      }

      .presentation-mode-toggle {
        padding: 0 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-weight: bold;
          color: $colour-grey-darkest;
        }

        .toggle-group {
          .btn {
            align-items: center;
            justify-content: center;
          }

          .toggle-on {
            color: $colour-white-pure;
          }

          .toggle-off {
            padding-left: 20px;
          }
        }
      }
    }
  }

  ul {
    margin: auto;

    li.messages-link {
      display: flex;

      a {
        display: inherit;
        
        .badge {
          margin-left: 2px;
        }
      }

      a:hover {
        background-color: $colour-white-pure;

        svg path {
          fill: var(--secondary-brand-colour);
        }

        .badge {
          background-color: var(--secondary-brand-colour);
        }
      }
    }
  }
}

.layout {
  .layout-header-wrapper {
    position: relative;

    .layout-header {
      background-color: $colour-white-pure;
      display: flex;
      flex-direction: row;
      justify-content: space-between; 
      align-items: stretch;
      border-bottom: 1px solid $colour-grey-light;

      .layout-header-left {
        display: flex;
      }

      .layout-header-right {
        display: flex;
      }
    }
  }

  .layout-content {
    margin-left: 15px;
    margin-right: 15px;
  }

  .layout-footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 100;
  }
}

@media (min-width:768px) {
  .home-logo {
    img {
      max-width: 175px;
    }
  }

  .main-navigation-bar {
    border-top: none;
    justify-content: flex-start;
    padding: 0;
    padding-left: 20px;
    width: auto;

    ul.navbar-nav {
      flex-wrap: nowrap;
    }
  }
  
  .administration-bar {
    .icon-button {
      margin: 0;
    }

    .navbar-right {
      #options-menu-dropdown {
        padding: 0 15px;
      }

      .dropdown-menu {
        left: auto;
        width: 350px;
        padding: 10px 0;
        top: calc(100% - 1px);
        right: 0px;
        border: 0;
        border-top: $colour-white-pure;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: $colour-white-pure;

        .icon-text-content-wrapper {
          padding: 0 25px;
        }

        li>a {
          padding: 5px 0px;
        }
      }
    }
  }

  .layout {
    .layout-content {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

@media screen and (max-height: 550px) {
  .layout {
    .layout-footer {
      position: relative;
      z-index: 0;
    }
  }
}
