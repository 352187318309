@import '../App/InvestoristColours';

.trialExpiredBannerWrap {
  margin-left: -15px;
  margin-right: -15px;
}

.trialExpiredBanner {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 10px 0;
  background-color: $colour-primary;
}

.trialExpiredBanner .bannerIcon {
  margin-top: 3px;
}

.trialExpiredBanner .bannerText {
  margin-top: 5px;
  padding-left: 5px;
}

.trialExpiredBanner .bannerText p {
  color: white;
}

.trialExpiredBanner .bannerBtn,  .trialExpiredBanner .bannerBtn:hover{
  background-color: white !important;
  color: $colour-primary !important;
}
.trialPopUp .phoneSection img {
  max-width: 25px;
  max-height: 25px;
}

@media (max-width: 991px) {
  .trialExpiredBanner .bannerText {
    padding-left: 30px;
    margin-top:0;
  }
  .trialExpiredBanner .bannerBtnWrapper {
    padding-right: 30px;
  }
  .bumpTopMobile {
    margin-top: 20px;
  }
  .trialPopUp a {
    font-size: 12px;
    color: $colour-grey-darkest;
  }
}